import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout/Index';
import qualityEngine from './modules/quality-engine';
import enterpriseManage from './modules/console';

// 解决编程式路由往同一地址跳转时会报错的情况
/* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch */
// 先存储默认底层的push
const originPush = VueRouter.prototype.push;
// 覆盖原型链的push
VueRouter.prototype.push = function (location, resolve, reject) {
  // this:路由实例对象

  // 判断用户有没有传后面两个可选参数
  if (resolve || reject) {
    return originPush.call(this, location, resolve, reject);
  } else {
    // 用户只传了第一个参数
    /*
      默认底层： catch()方法代码  throw err : 抛出异常
      吞掉报错原理： 重写catch()方法,把默认底层的 throw err给去掉，就不会抛出异常
      */
    return originPush.call(this, location).catch(() => {
      //  throw err
    });
  }
};

Vue.use(VueRouter);

export const dynamicRoutes = [];

export const initRoutes = [
  {
    path: '/404',
    component: () => import('@/layout/404'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/portal',
    hidden: true
  },
  {
    path: '/portal',
    name: 'portal',
    hidden: true,
    component: () =>
      import(/* webpackChunkName: "portal" */ '@/views/portal/Index.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: { title: '用户管理', icon: 'el-icon-s-help' },
    component: Layout,
    redirect: '/user/depart',
    children: [
      {
        path: 'depart',
        name: 'depart',
        meta: { title: '部门与用户' },
        component: () =>
          import(/* webpackChunkName: "depart" */ '@/views/departments/index')
      }
      // {
      //   path: 'test',
      //   name: 'test',
      //   meta: { title: 'test' },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "depart" */ '@/views/console/redash/index.vue'
      //     )
      // }
    ]
  },
  { ...enterpriseManage },
  /* 质检引擎路由 */
  { ...qualityEngine },
  {
    path: '/user-license',
    name: 'user-license',
    meta: { title: '授权管理', icon: 'el-icon-s-help' },
    component: Layout,
    redirect: '/user-license/show',
    children: [
      {
        path: 'show',
        name: 'show',
        meta: { title: '授权中心' },
        component: () =>
          import(
            /* webpackChunkName: "user-license" */ '@/views/user-license/Show.vue'
          )
      }
    ]
  },
  {
    path: '/license',
    name: 'license',
    meta: { title: '授权中心', icon: 'el-icon-s-help' },
    component: Layout,
    redirect: '/license/manage',
    children: [
      {
        path: 'apply',
        name: 'apply',
        meta: { title: '申请' },
        component: () =>
          import(
            /* webpackChunkName: "license" */ '@/views/license/apply/Index.vue'
          )
      },
      {
        path: 'import',
        name: 'import',
        meta: { title: '导入' },
        component: () =>
          import(
            /* webpackChunkName: "license" */ '@/views/license/import/Index.vue'
          )
      },
      {
        path: 'distribute',
        name: 'distribute',
        meta: { title: '分配' },
        component: () =>
          import(
            /* webpackChunkName: "license" */ '@/views/license/distribute/Index.vue'
          )
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/TheLogin')
  },

  { path: '*', redirect: '/404', hidden: true }
];

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: initRoutes
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = new VueRouter({
    routes: initRoutes
  });
  router.matcher = newRouter.matcher;
}
export default router;
