const str = location.hostname;
let domain = '';
let tsMainDomain = ''; // 中台服务
let axiosBaseUrl = '';

if (str) {
  if (str.startsWith('localhost')) {
    domain = '';
    tsMainDomain = '';
  } else {
    tsMainDomain = `//${str}`;
    axiosBaseUrl = '/';
  }
}

export { domain, tsMainDomain, axiosBaseUrl };
